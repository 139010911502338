@import "../../../../constants/Colors.scss";

.edit-icon-button {
  background-color: transparent;
  border: 0;
  padding: 2px 8px;
  margin-right: 4px;

  svg path {
    fill: $green;
    transition: fill 0.25s ease-in-out;
  }

  &:hover {
    svg path {
      fill: $greenHover;
    }
  }
}
.delete-icon-button {
  background-color: transparent;
  border: 0;

  svg path {
    fill: $red;
    transition: fill 0.25s ease-in-out;
  }

  &:hover {
    svg path {
      fill: $redError;
    }
  }
}