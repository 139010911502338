.btn {
	width: 300px;
	margin-left: 250px;
	margin-bottom: 30px;
	margin-top: 30px;
}

.border {
	border: 0px solid white;
}

.font {
	font-family: 'Bulldog';
}
