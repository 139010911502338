@import "../../../../constants/Colors.scss";

.card-item-wrapper {
  position: relative;
  max-width: 375px;
  width: 100%;
  min-height: 422px;
  margin-bottom: 20px;
  padding-top: 203px;
  background-color: $background;
  border-radius: 4px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: box-shadow 0.25s ease-in-out;

  &:hover {
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.15);
  }
}

.card-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 203px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px 4px 0 0;
  min-height: 40px;
}

.card-content-wrapper {
  min-height: 151px;
  padding: 12px;
  border-radius: 0 0 4px 4px;
  padding-bottom: 20px;
}

.card-content-tag {
  position: absolute;
  top: 12px;
  left: 12px;
  color: #fff;
  background-color: #333;
  border-radius: 20px;
  padding: 0 10px;
  text-transform: uppercase;
}

.card-content-heading {
  margin-bottom: 10px;
  font-weight: 800;
  font-size: 19px;
  line-height: 23px;
  text-shadow: 0px 0px 1px #333333;
  letter-spacing: 0.7px;
  color: #333333;
}

.card-status-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.card-status-text {
  font-size: 14px;
  line-height: 15px;
  color: #848484;
}

.card-status-value {
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: $orange;
}

.card-content-description {
  font-size: 17px;
  line-height: 21px;
  color: #373737;
  letter-spacing: 0.3px;
}

.card-item-no-image {
  position: relative;
  max-width: 375px;
  width: 100%;
  // min-height: "261px";
  margin-bottom: 20px;
  padding-top: 42;
  background-color: $background;
  border-radius: 4px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: box-shadow 0.25s ease-in-out;

  &:hover {
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.15);
  }

  .card-content-wrapper {
    padding-top: 50px;
  }
}