@import "../../../../constants/Colors.scss";
@import "../../../../constants/Styles.scss";

.header-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  text-decoration: none;
  padding: 8px 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: $orange;
  background-color: $white;
  border-radius: $borderRadius;
  border: 1px solid $orange;
  transition-property: background-color, color;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;

  &:hover {
    background: $lightOrange;
    color: $red;
  }

  &:not(:last-of-type) {
    margin-right: 8px;
  }

}

.header-link--active {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 8px 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: $white;
  background-color: $orange;
  border-radius: $borderRadius;
  border: 1px solid $orange;
  transition-property: background-color, border-color, color;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;

  &:hover {
    background-color: $red;
    border-color: $red;
    color: $white;
  }

  &:not(:last-of-type) {
    margin-right: 8px;
  }
}