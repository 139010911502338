@import "../../../../constants/Colors.scss";

* {
  border-collapse: collapse;
}

td,
th {
  padding: 8px;
  font-size: 15px;
  line-height: 16px;
  color: #000000;
}

th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  color: $grey;
  background-color: rgba($lightOrange, 0.7);
}

tr {
  border-bottom: 1px solid rgba($lightOrange, 0.4) !important;
}

tbody tr:hover {
  transition: background-color 0.25s ease-in-out;
  background-color: rgba($lightOrange, 0.4);
}

table {
  width: 100%;
}
