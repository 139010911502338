@import "../../../constants/Colors.scss";

.content-wrapper {
  width: 100%;
}

.filter-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: calc(100% + 32px);
  padding: 16px;
  margin: -16px -16px 0;
  min-height: 80px;

  &::after {
    content: "";
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    width: 100%;
    height: 30px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.04) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  & > div:not(:last-of-type) {
    margin-right: 20px;
  }
}

.search-button-wrapper {
  align-self: flex-start;
  width: 100px;
  margin-left: auto;
}

.search-and-add-button-wrapper {
  align-self: flex-start;
  width: 420px;
  display: flex;
  margin-left: auto;
  flex-direction: row;
  justify-content: space-between;
}
