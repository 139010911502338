@import "../../../../constants/Colors.scss";

.header-wrapper {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $background;
  z-index: 100;
  font-family: "Dosis";
}

.header-content {
  display: flex;
  align-items: center;
  max-width: 1444px;
  width: 100%;
  min-height: 72px;
  margin: 16px 16px 0 16px;
  padding: 0 22px;
  background-color: $white;
  border-radius: 6px 6px 0px 0px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.header-logo-wrapper {
  margin-right: 6px;
}

.header-logout-wrapper {
  margin-left: auto;

  button {
  width: 100%;
  height: 40px;
  padding: 8px 10px 10px;
  background-color: $white;
  border: 2px solid #848484;
  border-radius: 7px;
  font-family: "Dosis";
  font-size: 14px;
  font-weight: 600;
  color: #848484;
  transition-property: color, background-color;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  &:hover {
    cursor: pointer;
  background-color: #848484;
  color: $white;
  }
}
}
