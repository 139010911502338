@import "../../../constants/Colors.scss";

.paragraph {
	font-family: "Dosis";
	font-weight: 600;
}

.content-wrapper {
	width: 300;
}
