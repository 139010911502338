@import "../../../../constants/Colors.scss";
@import "../../../../constants/Styles.scss";

.input-label {
  float: left;
  clear: left;
  padding-left: 6px;
  font-family: "Dosis";
  font-size: 18px;
  font-weight: 600;
}

.input-container {
  position: relative;
}

.input-field {
  width: 98%;
  height: 36px;
  margin-bottom: 30px;
  margin-top: 6px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: $borderRadius;
  -khtml-border-radius: $borderRadius;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Dosis";
  font-size: 15px;
  font-weight: 600;
  transition-property: background-color, border-color, color;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  z-index: 0;
  &:focus {
    outline: 1px solid $lightOrange;
  }
}

.input-field::placeholder {
  font-family: "Dosis" !important;
  font-weight: 600 !important;
  color: $bgLightGrey;
}

.icon {
  position: absolute;
}

.error {
  float: left;
  clear: left;
  margin-top: -26px;
  padding-left: 5px;
  font-family: "Dosis";
  font-size: 12px;
  font-weight: 600;
  color: $redError;
}

.error-div {
  background-color: $redError;
}

.regular-div {
  background-color: $bgLightGrey;
}

.input-error {
  width: 98%;
  height: 36px;
  margin-bottom: 30px;
  margin-top: 6px;
  padding-right: 30px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  -khtml-border-radius: 7px;
  padding-left: 10px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  font-family: "Dosis";
  font-size: 15px;
  font-weight: 600;
  background-color: #ffcccb;
  transition-property: background-color, border-color, color;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  &:focus {
    outline: 1px solid #b30000;
  }
}

.input-error::placeholder {
	font-family: "Dosis" !important;
	font-weight: 600 !important;
  }
