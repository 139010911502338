@import "../../../constants/Colors.scss";

.login-body {
  height: 100vh;
  display: flex;
  font-family: bulldog;
}

.login-form {
  width: 30%;
  min-width: 360px;
  height: inherit;
  align-items: center;
  text-align: center;
  background-color: $white;
}

.logo-wrapper {
  padding-top: 10vh;
  margin-bottom: 50px;
}

.logo {
  width: 50%;
}

.form-wrapper {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

.right-panel {
  width: 70%;
  height: inherit;
  background: linear-gradient(117.85deg, $red 2.82%, $orange 100%);
}
